import { useEffect, useState } from "react";
import { getDetalhesDaObra } from "../Repository/domain/Pedidos";
import { getFornecedores } from "../Repository/domain/Servicos";

export const useFormData = (
  empresaSelecionada: string,
  obraSelecionada: string
) => {
  const [fornecedores, setFornecedores] = useState<any>([]);
  const [estado, setEstado] = useState<string>("");

  useEffect(() => {
    const fetchFornecedores = async () => {
      try {
        const fornecedores: any = await getFornecedores(
          empresaSelecionada,
          obraSelecionada
        );
        setFornecedores(fornecedores);
      } catch (error) {
        alert("Erro ao buscar detalhes do pedido:" + error);
      }
    };
    if (empresaSelecionada && obraSelecionada) {
      fetchFornecedores();
    }
  }, [empresaSelecionada, obraSelecionada]);

  useEffect(() => {
    const fetchDetalhesDaObra = async () => {
      try {
        const detalhesDaObra: any = await getDetalhesDaObra(
          empresaSelecionada,
          obraSelecionada
        );

        const uf = detalhesDaObra.uf_obr;
        setEstado(uf);
      } catch (error) {
        alert("Erro ao buscar detalhes do pedido:" + error);
      }
    };
    if (empresaSelecionada && obraSelecionada) {
      fetchDetalhesDaObra();
    }
  }, [empresaSelecionada, obraSelecionada]);

  return { fornecedores, estado };
};
