import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { verificaPermissaoUsuario } from "../Utils/Permissoes";
import { extrairGruposDoTokenGA } from "../Utils/TokensUtils";

export const usePermissao = () => {
  const [temPermissao, setTemPermissao] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;
  const etapa = pedidoExistente?.etapaStatus;
  useEffect(() => {
    const tokenGA = localStorage.getItem("tokenGA");
    if (tokenGA && etapa && responsavelPelaEtapa) {
      const permissao = verificaPermissaoUsuario(
        tokenGA,
        etapa,
        responsavelPelaEtapa
      );
      setTemPermissao(permissao);
    } else {
      setTemPermissao(false);
    }
  }, [etapa, responsavelPelaEtapa]);
  return temPermissao;
};

export const useExibcaoCamposAprovacao = () => {
  const [temPermissao, setTemPermissao] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const acao = pedidoExistente?.acao;
  useEffect(() => {
    if (acao) {
      setTemPermissao(acao);
    } else {
      setTemPermissao(false);
    }
  }, [acao]);
  return temPermissao;
};

export const usePermissaoSuprimentos = () => {
  const [temPermissaoSuprimentos, setTemPermissaoSuprimentos] = useState(false);

  useEffect(() => {
    const tokenGA = localStorage.getItem("tokenGA");
    if (tokenGA) {
      const grupos = extrairGruposDoTokenGA(tokenGA);
      const usuarioSuprimentos = grupos.some((grupo) =>
        /SUPRIMENTOS/.test(grupo)
      );

      setTemPermissaoSuprimentos(usuarioSuprimentos);
    } else {
      setTemPermissaoSuprimentos(false);
    }
  }, []);

  return temPermissaoSuprimentos;
};

export const usePermissaoSuprimentos4 = () => {
  const [temPermissaoSuprimentos, setTemPermissaoSuprimentos] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;

  useEffect(() => {
    const tokenGA = localStorage.getItem("tokenGA");
    if (tokenGA && responsavelPelaEtapa === 'Suprimentos 4') {
      const grupos = extrairGruposDoTokenGA(tokenGA);
      const usuarioSuprimentos = grupos.some((grupo) =>
        /SUPRIMENTOS4/.test(grupo)
      );

      setTemPermissaoSuprimentos(usuarioSuprimentos);
    } else {
      setTemPermissaoSuprimentos(false);
    }
  }, [responsavelPelaEtapa]);

  return temPermissaoSuprimentos;
};

export const usePermissaoSuprimentos3 = () => {
  const [temPermissaoSuprimentos, setTemPermissaoSuprimentos] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;
  const etapa = pedidoExistente?.etapaStatus;

  useEffect(() => {
    const tokenGA = localStorage.getItem("tokenGA");
    if (tokenGA && responsavelPelaEtapa === 'Suprimentos 3' && etapa === "Aprovação do mapa de equalização Suprimentos 3") {
      const grupos = extrairGruposDoTokenGA(tokenGA);
      const usuarioSuprimentos = grupos.some((grupo) =>
        /SUPRIMENTOS3/.test(grupo)
      );

      setTemPermissaoSuprimentos(usuarioSuprimentos);
    } else {
      setTemPermissaoSuprimentos(false);
    }
  }, [responsavelPelaEtapa]);

  return temPermissaoSuprimentos;
};

export const usePermissaoParaFinalizacao = () => {
  const [permitirFinalizacao, setPermitirFinalizacao] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;
  const etapa = pedidoExistente?.etapaStatus;

  useEffect(() => {
    const tokenGA = localStorage.getItem("tokenGA");
    if (tokenGA && responsavelPelaEtapa === 'Suprimentos 1' && etapa === "Contratação do Serviço - Suprimentos 1") {
      const grupos = extrairGruposDoTokenGA(tokenGA);
      const usuarioSuprimentos = grupos.some((grupo) =>
        /SUPRIMENTOS1/.test(grupo)
      );

      setPermitirFinalizacao(usuarioSuprimentos);
    } else {
      setPermitirFinalizacao(false);
    }
  }, [responsavelPelaEtapa, etapa]);

  return permitirFinalizacao;
};

export const usePermissaoParaReinicio = () => {
  const [permitirReinicio, setPermitirReinicio] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;
  const etapa = pedidoExistente?.etapaStatus;

  useEffect(() => {
    const tokenGA = localStorage.getItem("tokenGA");
    if (tokenGA && responsavelPelaEtapa === 'Suprimentos 1' && etapa === "Contratação do Serviço - Suprimentos 1") {
      setPermitirReinicio(true);
    } else {
      setPermitirReinicio(false);
    }
  }, [responsavelPelaEtapa, etapa]);

  return permitirReinicio;
};

export const usePermissaoParaReabertura = () => {
  const [permitirReabertura, setPermitirReabertura] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;
  const etapa = pedidoExistente?.etapaStatus;

  useEffect(() => {
    const tokenGA = localStorage.getItem("tokenGA");
    if (tokenGA && responsavelPelaEtapa === 'Suprimentos 1' && etapa === "Finalizado") {
      setPermitirReabertura(true);
    } else {
      setPermitirReabertura(false);
    }
  }, [responsavelPelaEtapa, etapa]);

  return permitirReabertura;
};

export const useFinalizacaoPedidoSup3 = () => {
  const [finalizarPedidoSup3, setFinalizarPedidoSup3] = useState(false);
  const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
  const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;
  const etapa = pedidoExistente?.etapaStatus;

  useEffect(() => {
    if (responsavelPelaEtapa === 'Suprimentos 3' && etapa === "Aprovação - Justificativa") {
      setFinalizarPedidoSup3(true);
    } else {
      setFinalizarPedidoSup3(false);
    }
  }, [responsavelPelaEtapa, etapa]);
  return finalizarPedidoSup3;
};