import { KeyboardArrowDown, Remove } from '@mui/icons-material';
import {
  Autocomplete,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CodigoPLM } from '../../Interface/ICodigoPLM';
import { removeQuantitativoSelecionado, updateQuantitativos } from '../../redux/pedido/sliceViewModelEntrada';
import { getCodigoPLM } from '../../Repository/domain/Servicos';
import './style.scss';

const TabelaQuantitativos: React.FC = () => {
  const dispatch = useDispatch();
  const novoPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
  const [codigoPLM, setCodigoPLM] = useState<CodigoPLM[]>([]);
  const mode = useSelector((state: any) => state.modeSlice.mode);
  const desabilitadoTabela = useSelector((state: any) => state.modeSlice.desabilitadoTabela);
  const quantitativosExistentes = useSelector((state: any) => state.pedidoSliceViewModelSaida.quantitativos);
  const quantitativosSelecionados = useSelector((state: any) => state.pedidoSliceViewModelEntrada.quantitativos);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    if (novoPedido.codigoEmpresa && novoPedido.codigoObra) {
      const fetchObterCodigoPLM = async () => {
        try {
          const codigo: CodigoPLM[] = await getCodigoPLM(novoPedido.codigoEmpresa, novoPedido.codigoObra);
          const codigoUnico = codigo.filter(
            (item, index, self) =>
              self.findIndex((i) => i.codigoPLM === item.codigoPLM) === index
          );
          setCodigoPLM(codigoUnico);
        } catch (error) {
          alert("Erro ao buscar os códigos PLM do pedido:" + error);
        }
      };
      fetchObterCodigoPLM();
    }
  }, [novoPedido.codigoEmpresa, novoPedido.codigoObra]);

  useEffect(() => {
    if (quantitativosSelecionados && Array.isArray(quantitativosSelecionados)) {
      const novosRows = quantitativosSelecionados.map((quantitativo: any) => ({
        id: quantitativo.codigoComposicao,
        codigoComposicaoItem: quantitativo.codigoComposicao,
        descricaoServico: quantitativo.descricaoComposicao,
        unidade: quantitativo.unidade || '',
        quantidadeSolicitada: quantitativo.quantidadeSolicitada || '',
        codigoPLM: quantitativo.codigoPLM || 0,
        observacoes: quantitativo.observacoes || '',
        categoriaUAUCodigoPLM: quantitativo.categoriaUAUCodigoPLM || '',
      }));
      setRows(novosRows);
    }
  }, [quantitativosSelecionados]);

  const handleInputChange = (id: string, field: string, value: string | number) => {
    dispatch(updateQuantitativos({ id, field, value }));
  };

  const columns = [
    { field: 'codigoItem', header: 'Código do item', width: 100, align: 'center' as const },
    { field: 'descricaoServico', header: 'Descrição do serviço', width: 180 },
    { field: 'unidadeMedida', header: 'Unidade de Medida', width: 100 },
    { field: 'quantidadeSelecionada', header: 'Quantidade Solicitada', width: 120, },
    { field: 'codigoPLM', header: 'Código PLM', width: 270 },
    { field: 'observacoes', header: 'Observações', width: 200 },
    { field: 'acao', header: 'Ação', width: 60, align: 'center' as const },
  ];

  const handleRemoverQuantitativo = (id: string) => {
    const quantitativoAntigo = quantitativosExistentes?.some(
      (quantitativo: { codigoComposicao: string }) => quantitativo.codigoComposicao === id
    );
    if (mode === "create" || (mode === "view" && !quantitativoAntigo)) {
      dispatch(removeQuantitativoSelecionado(id));
    } else if (mode === "view" && quantitativoAntigo) {
      const item = quantitativosSelecionados.find(
        (quantitativo: { codigoComposicao: string; }) => quantitativo.codigoComposicao === id
      );
      if (item) {
        dispatch(updateQuantitativos({
          id,
          field: 'descricaoComposicao',
          value: (item.descricaoComposicao.match(/\*/g) || []).length === 1
            ? `*${item.descricaoComposicao}`
            : `**${item.descricaoComposicao}`
        }));
      }
    }
  };
  return (
    <div className='tableQuantitativo'>
      {
        codigoPLM.length === 0 && (
          <>
            <span style={{ fontSize: '15px', fontWeight: 'bold' }}>* É obrigatório que o PLM tenha vínculo com uma verba para estar disponível.
            </span>
            <br />
            <br />
          </>
        )
      }
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                (column.field === 'codigoPLM' ?
                  <TableCell key={column.field} style={{ width: column.width }}>
                    {column.header} <span style={{ color: 'red' }}>*</span>
                  </TableCell> :
                  <TableCell key={column.field} style={{ width: column.width }}>
                    {column.header}
                  </TableCell>
                )
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const asteriscos = (row.descricaoServico.match(/\*/g) || []).length;
              const linhaClasse = asteriscos === 2 ? 'linha-vermelha' : (asteriscos === 1 ? 'linha-verde' : '');
              const tamanhoObservacao = row.observacoes.length;
              const caracteresRestantes = 1000 - tamanhoObservacao;
              return (
                <TableRow key={row.id}>
                  <TableCell align='center' className={linhaClasse}>{row.codigoComposicaoItem || '-'}</TableCell>
                  <TableCell className={linhaClasse}>{row.descricaoServico || '-'}</TableCell>
                  <TableCell className={linhaClasse}>
                    {row.unidade || ''}
                  </TableCell>
                  <TableCell className={linhaClasse}>
                    <TextField
                      disabled={desabilitadoTabela || asteriscos === 2}
                      type="number"
                      className="inputTabela"
                      value={row.quantidadeSolicitada}
                      onChange={(e) => {
                        const valor = parseFloat(e.target.value);
                        const valorFormatado = isNaN(valor) ? '' : valor.toFixed(4).replace(/(\.0+|0+)$/, '');
                        handleInputChange(row.id, 'quantidadeSolicitada', parseFloat(valorFormatado));
                      }}
                    />
                  </TableCell>
                  <TableCell className={linhaClasse}>
                    <Autocomplete
                      disabled={desabilitadoTabela || asteriscos === 2}
                      className="inputTabela"
                      options={codigoPLM}
                      value={codigoPLM.find((codigo) => codigo.codigoPLM === row.codigoPLM) || null}
                      getOptionLabel={(option) => option.codigoPLM === '' ? '' : `${option.codigoPLM} - ${option.descricao}`}
                      isOptionEqualToValue={(option, value) => option.codigoPLM === value.codigoPLM}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          slotProps={{ inputLabel: { shrink: false, } }}
                        />
                      )}
                      popupIcon={<KeyboardArrowDown style={{ fontSize: '18px', marginRight: '5px' }} />}
                      noOptionsText="Nenhuma opção"
                      onChange={(_event, newValue) => {
                        handleInputChange(row.id, 'codigoPLM', String(newValue?.codigoPLM))
                      }}
                    />
                  </TableCell>
                  <TableCell className={linhaClasse} sx={{ whiteSpace: "normal", p: 1 }}>
                    <TextField
                      disabled={desabilitadoTabela || asteriscos === 2}
                      className="inputTabela"
                      multiline
                      maxRows={6}
                      fullWidth
                      variant="outlined"
                      sx={{
                        "& .MuiInputBase-root": {
                          alignItems: "start",
                          padding: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "auto",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            boxShadow: caracteresRestantes === 0 ? "0 0 0 0.1rem rgba(247, 0, 0, 0.83)" : "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
                          },
                        },
                      }}
                      defaultValue={row.observacoes}
                      onChange={(e) => handleInputChange(row.id, 'observacoes', e.target.value)}
                      slotProps={{
                        htmlInput: { maxLength: 1000 }
                      }}
                    />
                    {caracteresRestantes <= 100 && (
                      <Typography
                        sx={{
                          textAlign: "right",
                          color: "red",
                          marginTop: "5px",
                          fontSize: "13px",
                        }}
                      >
                        {caracteresRestantes === 0
                          ? "Número de caracteres excedido"
                          : `${caracteresRestantes} caracteres restantes`}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <button
                      disabled={desabilitadoTabela || asteriscos === 2}
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => { handleRemoverQuantitativo(row.id) }}
                    >
                      <Remove style={{ fontSize: '15px' }} />
                    </button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div >
  );
};

export default TabelaQuantitativos;