import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import useLogin from "../../Hooks/useLogin";
import { LoadingProps } from "../../Interface/ILoading";
import Usuario from "../../Interface/IUsuario";
import { AutenticacaoService } from "../../Services/AutenticacaoService";

const Login: React.FC<LoadingProps> = ({ setLoading }) => {
  const { tela, tokenCognito, tokenGA, accessToken } = useParams();
  const { logado, alterarUsuarioLogado } = useLogin();
  const autenticacaoService = new AutenticacaoService();

  useEffect(() => {
    const autenticacao = async () => {
      setLoading(true);

      autenticacaoService.gravarTokens(
        tokenCognito as string,
        accessToken as string,
        tokenGA as string
      );

      const decodedTokenGA: any = jwtDecode(tokenGA as any);

      const usuario: Usuario = {
        matricula: decodedTokenGA["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
        nome: decodedTokenGA["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]
      };
      autenticacaoService.gravarUsuarioLogado(usuario);
      alterarUsuarioLogado(true);
    };

    autenticacao()
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  });

  return logado ? <Navigate to={`/${tela}/`} /> : <></>;
};

export default Login;
