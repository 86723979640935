import { DadosQuantitativos } from "../../Interface/ITabelaQuantitativo";

export const validarFormulario = ({ novoPedido }: { novoPedido?: any; }, showAlertMessage: (message: string, type: "success" | "warning" | "danger" | "info") => void) => {
    
    let formularioValido;

    if(novoPedido.descricaoTipoSolicitacao == "Contratação Inicial")
    {
        formularioValido = !!(
            novoPedido?.codigoUsuario &&
            novoPedido?.codigoEmpresa &&
            novoPedido?.codigoObra &&
            novoPedido?.descricaoTipoSolicitacao &&
            novoPedido?.idServico
        );
    } else {
        formularioValido = !!(
            novoPedido?.codigoUsuario &&
            novoPedido?.codigoEmpresa &&
            novoPedido?.codigoObra &&
            novoPedido?.descricaoTipoSolicitacao &&
            novoPedido?.idServico &&
            novoPedido?.fornecedor &&
            novoPedido?.processoAnterior
        );
    }
    
    
    const quantitativosValidos = novoPedido?.quantitativos.length > 0;
    const orcamentosValidos = novoPedido?.orcamentos.itemSemOrcamento === true ? true : novoPedido?.orcamentos.orcamentoItens.length > 0;
    const orcamentoValido = novoPedido?.orcamentos.itemSemOrcamento === false ? true : novoPedido?.orcamentos.itemSemOrcamento === true &&
        novoPedido?.orcamentos.justificativa?.trim() !== "";
    const dataAssinatura = novoPedido?.dataAssinatura;
    const dataInicio = novoPedido?.dataInicioExecucao;
    const dataFim = novoPedido?.dataFimExecucao;
    const pedidoEmergencialValido =
        !novoPedido?.pedidoEmergencial || novoPedido?.justificativaSelecionada;
    const isInclusaoServico = novoPedido?.descricaoTipoSolicitacao != "";

    const contratacaoInicial = !!novoPedido?.processoAnterior;

    const formValido =
        formularioValido &&
        quantitativosValidos &&
        orcamentosValidos &&
        orcamentoValido &&
        dataAssinatura &&
        dataInicio &&
        dataFim &&
        pedidoEmergencialValido;
    
    if (!formValido) {

        if (novoPedido?.descricaoTipoSolicitacao != "Contratação Inicial" && !contratacaoInicial) {
            showAlertMessage("O número da contratação inicial é obrigatório.", "danger");
            return;
        }

        if (!formularioValido) {
            showAlertMessage(
                "Preencha todos os campos obrigatórios do formulário.",
                "danger"
            );
            return;
        } else if (!quantitativosValidos) {
            showAlertMessage("Verifique o campo de itens da solicitação/cotação", "danger");
            return;
        } else if (!orcamentosValidos) {
            showAlertMessage("Selecione o campo um item do orçamento.", "danger");
            return;
        } else if (!orcamentoValido) {
            showAlertMessage("Justificativa do orçamento é obrigatória.", "danger");
            return;
        } else if (!dataAssinatura) {
            showAlertMessage("Preencha o campo data necessaria para assinatura do contrato.", "danger");
            return;
        } else if (!dataInicio) {
            showAlertMessage("Preencha o prazo para execução do serviço (início):.", "danger");
            return;
        } else if (!dataFim) {
            showAlertMessage("Preencha o Prazo para execução do serviço (fim):.", "danger");
            return;
        } else if (!pedidoEmergencialValido) {
            showAlertMessage("Preencha a justificativa para pedido emergencial.", "danger");
            return;
        }
        return false;
    }

    if (isInclusaoServico) {
        if (!novoPedido?.relacaoProjetos) {
            showAlertMessage(
                "Preencha a relação dos projetos a serem encaminhados aos prestadores de serviço.",
                "danger"
            );
            return false;
        }
        if (!novoPedido?.especificacaoMateriais) {
            showAlertMessage(
                "Preencha a especificação dos materiais a serem aplicados nos serviços.",
                "danger"
            );
            return false;
        }
        if (!novoPedido?.nbr) {
            showAlertMessage("Preencha as NBRs aplicáveis.", "danger");
            return false;
        }
        if (!novoPedido?.obrigacoesFornecedores) {
            showAlertMessage("Preencha as obrigações dos fornecedores.", "danger");
            return false;
        }
        if (!novoPedido?.fornecedoresIndicados) {
            showAlertMessage(
                "Preencha os fornecedores indicados (nome, telefone e e-mail).",
                "danger"
            );
            return false;
        }
        if (!novoPedido?.condicoesPagamentos) {
            showAlertMessage("Preencha as condições de pagamento.", "danger");
            return false;
        }
        if (!novoPedido?.criterioMedicao) {
            showAlertMessage("Preencha o critério de medição.", "danger");
            return false;
        }
        if (!novoPedido?.detalhamento) {
            showAlertMessage("Preencha o detalhamento do serviço.", "danger");
            return false;
        }

        if (novoPedido.quantitativos?.some((quantitativo: DadosQuantitativos) => !quantitativo.codigoPLM || quantitativo.codigoPLM === "")) {
            showAlertMessage("Preencha o código PLM dos itens da solicitação/cotação.", "danger");
            return false;
        }
    }

    return true;
};