import { DadosQuantitativos } from "../../Interface/ITabelaQuantitativo";

export const validarCodigoPLM = (
    pedidoAtualizado: any,
    showAlertMessage: (message: string, type: "success" | "warning" | "danger" | "info") => void) => {

    if (pedidoAtualizado.quantitativos?.some((quantitativo: DadosQuantitativos) => !quantitativo.codigoPLM || quantitativo.codigoPLM === "")) {
        showAlertMessage(
            "Preencha o código PLM dos itens da solicitação/cotação.",
            "danger"
        );
        return true;
    }
    return false;
}
