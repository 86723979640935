export function formatarMoedaBrasileira(valor: number | string): string {
    if (valor === "") return "";
    if (typeof valor === 'string') {
        valor = parseFloat(valor.replace(/[R$.,\s]/g, '')) / 100;
    }

    const valorFormatado = valor.toFixed(2);

    return parseFloat(valorFormatado).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });
}

export function mascaraMoeda(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    const input = event.target;
    const onlyDigits = input.value
        .split("")
        .filter((s) => /\d/.test(s))
        .join("")
        .padStart(3, "0");

    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    input.value = formatarMoedaBrasileira(digitsFloat);
}

export function formatarValor4CasasDecimais(valor: number): string {
    const valorFormatado = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 4,
    }).format(valor);
    return valorFormatado
}