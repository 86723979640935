import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { AlertaDialogProps } from '../../Interface/IAlertaDialogo';

const AlertDialog: React.FC<AlertaDialogProps> = ({ titulo, mensagem, opcao1, opcao2, open, handleOpcao1, handleOpcao2, duasOpcoes }) => {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {titulo}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {mensagem}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {duasOpcoes && (
                    <Button
                        style={{
                            backgroundColor: 'rgba(233, 55, 55)',
                            borderRadius: '15px', color: '#FFF', fontSize: '12px',

                        }}
                        onClick={handleOpcao1}>{opcao1}</Button>
                )}

                <Button
                    style={{
                        backgroundColor: 'rgb(13, 110, 253)',
                        borderRadius: '15px', color: '#FFF', fontSize: '12px'
                    }}
                    onClick={handleOpcao2} autoFocus>{opcao2}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;