import { useState } from "react";
import { Anexo } from "../Interface/IAnexos";
import { TipoDeSolicitacao } from "../Interface/IFormulario";
import { InsumoComposicaoProps } from "../Interface/IInsumoComposicao";
import { InsumoComposicaoAtualizadoProps } from "../Interface/ITabelaQuantitativo";
import { AutenticacaoService } from "../Services/AutenticacaoService";

const usePedidoState = () => {
  const [opcoesInsumoComposicao, setOpcoesInsumoComposicao] =
    useState<string>("Composicoes");
  const [itensSelecionadosQuantitativos, setItensSelecionadosQuantitativos] =
    useState<InsumoComposicaoProps[]>([]);
  const [itensSelecionadosOrcamentos, setItensSelecionadosOrcamentos] =
    useState<InsumoComposicaoProps[]>([]);
  const [dadosDaEmpresa, setDadosDaEmpresa] = useState<any>([]);
  const [dadosDosServicos, setDadosDosServicos] = useState<any>([]);
  const [codigoUsuario] = useState<string>(
    new AutenticacaoService().obterCodigoUsuario()
  );
  const [solicitacao, setSolicitacao] = useState<TipoDeSolicitacao | null>(
    null
  );
  const [estado, setEstado] = useState<string>("");
  const [dadosDaObra, setDadosDaObra] = useState<any>([]);
  const [codigoServicoBI, setCodigoServicoBI] = useState<string>("");
  const [prazoBI, setPrazoBI] = useState<any>("");
  const [arquivos, setArquivos] = useState<Anexo[]>([]);
  const [datas, setDatas] = useState<{
    pedidoEmergencial: boolean;
    dataDeAssinatura?: string;
    prazoParaContratacao?: string;
    prazoParaExecucaoInicial?: string;
    prazoParaExecucaoFinal?: string;
    justificativaSelecionada?: string;
  }>({
    pedidoEmergencial: false,
  });
  const [orcamentoExistente, setOrcamentoExistente] = useState(false);
  const [justificativa, setJustificativa] = useState("");
  const [itensSelecionados, setItensSelecionados] = useState<
    InsumoComposicaoAtualizadoProps[]
  >([]);
  const [orcamentosSelecionados, setOrcamentosSelecionados] = useState<
    InsumoComposicaoProps[]
  >([]);
  const [detalhesDoPedido, setdetalhesDoPedido] = useState<any>({
    justificativa: "",
    relacaoDeProjetos: "",
    especificacaoDosMateriais: "",
    nbr: "",
    obrigacoesFornecedor: "",
    fornecedoresIndicados: "",
    condicoesPagamento: "",
    criterioMedicao: "",
    detalhamentoDoServico: "",
  });

  return {
    datas,
    setDatas,
    estado,
    setEstado,
    arquivos,
    setArquivos,
    prazoBI,
    setPrazoBI,
    dadosDaObra,
    setDadosDaObra,
    justificativa,
    setJustificativa,
    dadosDaEmpresa,
    setDadosDaEmpresa,
    codigoServicoBI,
    setCodigoServicoBI,
    detalhesDoPedido,
    setdetalhesDoPedido,
    dadosDosServicos,
    setDadosDosServicos,
    itensSelecionados,
    setItensSelecionados,
    orcamentoExistente,
    setOrcamentoExistente,
    codigoUsuario,
    solicitacao,
    setSolicitacao,
    opcoesInsumoComposicao,
    setOpcoesInsumoComposicao,
    orcamentosSelecionados,
    setOrcamentosSelecionados,
    itensSelecionadosOrcamentos,
    setItensSelecionadosOrcamentos,
    itensSelecionadosQuantitativos,
    setItensSelecionadosQuantitativos,
  };
};

export default usePedidoState;
