import Usuario from "../../Interface/IUsuario";

export class AutenticacaoService {

  obterCodigoUsuario(): string {
    return localStorage.getItem("codigoUsuario") ?? "";
  }
  gravarUsuarioLogado(usuario: Usuario): void {
    localStorage.setItem("codigoUsuario", usuario.matricula);
  }
  gravarTokens(token: string, accessToken: string, tokenGA: string): void {
    localStorage.setItem("tokenCognito", token as string);
    localStorage.setItem("accessToken", accessToken as string);
    localStorage.setItem("tokenGA", tokenGA as string);
  }
  realizarLogout(): void {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tokenCognito");
    localStorage.removeItem("tokenGA");
    window.location.href = process.env
      .REACT_APP_URL_SAIR_AUTHENTICATOR as string;
  }
}
