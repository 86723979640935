import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAlert from "../../Hooks/useAlert";
import {
    useExibcaoCamposAprovacao,
    useFinalizacaoPedidoSup3,
    usePermissao,
    usePermissaoParaFinalizacao,
    usePermissaoParaReabertura,
    usePermissaoParaReinicio,
    usePermissaoSuprimentos3,
    usePermissaoSuprimentos4,
} from "../../Hooks/usePermissao";
import { AtualizarPedidoProps } from "../../Interface/IAtualizarPedido";
import {
    FinalizarPedido,
    VerificaDivergenciaContratual,
} from "../../Interface/IFinalizarPedido";
import { ParecerAprovacaoProps } from "../../Interface/IParecerAprovacao";
import client from "../../Repository/config/client";
import {
    deleteRemoverAnexoPedido,
    postCancelar,
    postFinalizar,
    postReabertura,
    postReiniciar,
    postVerificaDivergenciaContratual,
    putAtualizarAnexoPedido,
    putAtualizarPedido,
    putAtualizarPedidoSolicitante
} from "../../Repository/domain/Pedidos";
import { handleApiError } from "../../Utils/ErrorHandle";
import { validarCodigoPLM } from "../../Utils/ValidarCodigoPLM";
import { validarValorContratacaoInicial, validarValorOrcamento } from "../../Utils/ValidarOrcamento";
import AlertDialog from "../AlertaDialogo";
import AlertaBootstrap from "../Alertas";
import ModalConfirmacaoCancelar from "../ModalConfirmacaoCancelar";
import ModalConfirmacaoReabertura from "../ModalConfirmacaoReabertura";
import ModalConfirmacaoReiniciar from "../ModalConfirmacaoReinicar";

const ParecerAprovacao: React.FC<ParecerAprovacaoProps> = ({
    idPedido,
    contrato,
    setLoading,
    anexoAtualizado,
    anexoRemovido,
    anexosPedido,
    dadosResumoFinanceiro
}: ParecerAprovacaoProps) => {

    const navigate = useNavigate();
    const temPermissao = usePermissao();
    const exibirCampoAprovacao = useExibcaoCamposAprovacao();
    const permitirFinalizacao = usePermissaoParaFinalizacao();
    const permitirReinicio = usePermissaoParaReinicio();
    const permitirReabertura = usePermissaoParaReabertura();
    const temPermissaoSuprimentos3 = usePermissaoSuprimentos3();
    const temPermissaoSuprimentos4 = usePermissaoSuprimentos4();
    const finalizarPedidoSup3 = useFinalizacaoPedidoSup3();

    const [justificativaParecer, setJustificativaParecer] = useState("");
    const [showModalCancelar, setShowModalCancelar] = useState(false);
    const [showModalReiniciar, setShowModalReiniciar] = useState(false);
    const [showModalReabertura, setShowModalReabertura] = useState(false);
    const [contratoUAU, setContratoUAU] = useState<string>("");

    const { showAlert, alertMessage, alertType, showAlertMessage } = useAlert();
    const desabilitadoSolicitante = useSelector((state: any) => state.modeSlice.desabilitadoSolicitante);
    const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);

    const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;

    useEffect(() => {
        if (finalizarPedidoSup3) {
            setContratoUAU(contrato);
        }
    }, [finalizarPedidoSup3, contrato]);

    const handleParecerChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setJustificativaParecer(event.target.value);
    };

    const handleContratoUAUChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setContratoUAU(event.target.value);
    };

    const handleCancelarPedido = () => {
        if (justificativaParecer.trim() === "") {
            alert(
                "Por favor, é necessário fornecer uma justificativa para o cancelamento do pedido."
            );
            return;
        }
        setShowModalCancelar(true);
    };

    const handleReinicarPedido = () => {
        if (justificativaParecer.trim() === "") {
            alert(
                "Por favor, é necessário fornecer uma justificativa para o reinício do pedido."
            );
            return;
        }
        setShowModalReiniciar(true);
    };

    const handleReaberturaPedido = () => {
        setShowModalReabertura(true);
    };

    const pedidoAtualizado = useSelector((state: any) => state.pedidoSliceViewModelEntrada);

    const handleAtualizarPedido = async () => {
        try {
            const atualizarPedidoProps: AtualizarPedidoProps = {
                codigoPedido: pedidoAtualizado.codigoPedido,
                pedido: {
                    ...pedidoAtualizado,
                    fornecedores: [{ ...pedidoAtualizado.fornecedores }],
                }
            };
            await putAtualizarPedido(atualizarPedidoProps);
            return true;
        } catch (error) {
            console.error('Erro ao salvar itens.', error);
            return false;
        }
    }

    const handleAtualizarAnexo = async () => {
        try {
            await putAtualizarAnexoPedido(idPedido, anexoAtualizado);
            return true;
        } catch (error) {
            console.error('Erro ao salvar itens.', error);
            alert("Erro ao atualizar anexo.");
            return false;
        }
    };
    const handleRemoverAnexo = async () => {
        if (anexoRemovido.length > 0) {
            try {
                await deleteRemoverAnexoPedido(idPedido, anexoRemovido);
                return true;
            } catch (error) {
                console.error('Erro ao salvar itens.', error);
                alert("Erro ao remover anexo.");
                return false;
            }
        }
    }
    const handleAtualizarPedidoSolicitante = async () => {
        const dadosPedido = {
            codigoPedido: pedidoAtualizado.codigoPedido ?? 0,
            pedido: {
                ...pedidoAtualizado,
                fornecedores: [{ ...pedidoAtualizado.fornecedores }],
            }
        };
        try {
            await putAtualizarPedidoSolicitante(dadosPedido);
            return true;
        } catch (error) {
            console.error('Erro ao salvar itens.', error);
            return false;
        }
    }

    async function handleMovimentarPedido(
        aprovar: boolean,
        duvida: boolean
    ): Promise<void> {
        setLoading(true);
        const decisao = aprovar ? "Aprovar" : "Reprovar";

        if (justificativaParecer.trim() === "") {
            alert(
                `Por favor, é necessário fornecer uma justificativa para ${decisao} o pedido.`
            );
            setLoading(false);
            return;
        }

        const isCamposOrcamentoValidos = aprovar ? temPermissao && validarValorOrcamento(
            pedidoAtualizado,
            showAlertMessage,
            anexoAtualizado,
            anexosPedido,
            anexoRemovido
        ) : false;

        const isCamposContratacaoValidos = aprovar ? temPermissao && validarValorContratacaoInicial(
            pedidoAtualizado,
            showAlertMessage,
            dadosResumoFinanceiro
        ) : false;

        const isCodigoPLMValido = validarCodigoPLM(pedidoAtualizado, showAlertMessage);

        if (isCamposOrcamentoValidos || isCamposContratacaoValidos || isCodigoPLMValido) {
            setLoading(false);
            return;
        }
        try {
            if (anexoRemovido.length > 0) {
                const removeAnexoSuccess = await handleRemoverAnexo();
                if (!removeAnexoSuccess) {
                    throw new Error("Erro ao remover anexo.");
                }
            }

            if (anexoAtualizado.length > 0) {
                const atualizaAnexoSuccess = await handleAtualizarAnexo();
                if (!atualizaAnexoSuccess) {
                    throw new Error("Erro ao atualizar anexo.");
                }
            }

            if (desabilitadoSolicitante === false && responsavelPelaEtapa === "Solicitante") {
                const atualizaPedidoSuccess = await handleAtualizarPedidoSolicitante();
                if (!atualizaPedidoSuccess) {
                    throw new Error("Erro ao atualizar pedido.");
                }
            } else if (responsavelPelaEtapa === "Suprimentos 1" && temPermissao) {
                const atualizaPedidoSuccess = await handleAtualizarPedido();
                if (!atualizaPedidoSuccess) {
                    throw new Error("Erro ao atualizar pedido.");
                }
            }

            const headers = {
                "Content-Type": "application/json",
            };

            const payload = JSON.stringify({
                numeroPedido: idPedido,
                aprovado: aprovar,
                justificativa: justificativaParecer,
                duvida: duvida,
            });

            const response = await client.post("Pedido/movimentar", payload, headers);

            if (response) {
                setLoading(false);
                return navigate("/pedidos");
            } else {
                alert(`Não foi possível ${decisao} pedido!`);
                setLoading(false);
                return;
            }
        } catch (error) {
            setLoading(false);
            alert(
                `Não foi possível ${decisao} o pedido! Erro: ${handleApiError(error)}`
            );
        } finally {
            setLoading(false);
        }
    }

    const verificaDivergenciaContratual = async () => {
        const payload: VerificaDivergenciaContratual = {
            numeroPedido: idPedido,
            contratoUAU: parseInt(contratoUAU, 10),
        };
        try {
            setLoading(true);
            const response = await postVerificaDivergenciaContratual(payload);
            return response;
        } catch (error) {
            alert(
                `Contrato UAU não encontrado. Verifique o número e tente novamente. Erro: " ${handleApiError(
                    error
                )}`
            );
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const handleFinalizarPedido = async () => {
        debugger;
        setLoading(true);
        setAlertaDivergencia(false);
        setAlertaFinalizacaoPedido(false);
        try {
            if (anexoRemovido.length > 0 && !(await handleRemoverAnexo())) {
                throw new Error("Erro ao remover anexo.");
            }
            if (anexoAtualizado.length > 0 && !(await handleAtualizarAnexo())) {
                throw new Error("Erro ao atualizar anexo.");
            }
            const payload: FinalizarPedido = {
                numeroPedido: idPedido,
                contratoUAU: parseInt(contratoUAU, 10),
                justificativa: justificativaParecer,
            };

            const response = await postFinalizar(payload);

            if (response) {
                alert(`Pedido ${idPedido} finalizado.`);
                setTimeout(() => {
                    navigate("/pedidos");
                }, 2500);
            } else {
                alert("Erro ao finalizar o pedido" + response);
            }
        } catch (error) {
            alert(
                `Não foi possível finalizar o pedido. Erro: " ${handleApiError(error)}`
            );
        } finally {
            setLoading(false);
        }
    };

    const confirmarCancelamento = async () => {
        try {
            setLoading(true);
            const payload = {
                numeroPedido: idPedido,
                justificativa: justificativaParecer,
            };
            const response = await postCancelar(payload);
            if (response) {
                return navigate("/pedidos");
            } else {
                alert(`Não foi possível cancelar o pedido ${idPedido}`);
            }
        } catch (error) {
            alert(
                `Não foi possível cancelar o pedido. Erro: " ${handleApiError(error)}`
            );
        } finally {
            setShowModalCancelar(false);
            setLoading(false);
        }
    };

    const confirmarReinicio = async () => {
        try {
            setLoading(true);
            const payload = {
                numeroPedido: idPedido,
                justificativa: justificativaParecer,
            };
            const response = await postReiniciar(payload);
            if (response) {
                return navigate("/pedidos");
            } else {
                alert(`Não foi possível reiniciar o pedido ${idPedido}`);
            }
        } catch (error) {
            alert(
                `Não foi possível reiniciar o pedido. Erro: " ${handleApiError(error)}`
            );
        } finally {
            setShowModalReiniciar(false);
            setLoading(false);
        }
    };

    const confirmarReabertura = async () => {
        try {
            setLoading(true);
            const payload = {
                numeroPedido: idPedido,
            };
            const response = await postReabertura(payload);
            if (response) {
                return navigate("/pedidos");
            } else {
                alert(`Não foi possível reabrir o pedido ${idPedido}`);
            }
        } catch (error) {
            alert(
                `Não foi possível reabrir o pedido. Erro: " ${handleApiError(error)}`
            );
        } finally {
            setShowModalReabertura(false);
            setLoading(false);
        }
    };

    const validaContratoPresente = (): boolean => {
        if (
            pedidoAtualizado.etapa === "Contratação de serviço"
            && pedidoAtualizado.descricaoTipoSolicitacao === "Contratação Inicial"
            && !(anexoAtualizado.some((anexo: { tipoDocumento: string }) => anexo.tipoDocumento === "Contrato de Prestação de Serviço"))
        ) {
            showAlertMessage(
                "Erro ao finalizar o pedido: É necessário anexar o Contrato de Prestação de Serviço.",
                "warning"
            );
            return false;
        }
        return true;
    }

    const isParecerValido = justificativaParecer.trim() !== "";
    const isFinalizarValido = contratoUAU.trim() !== "" && !!idPedido && justificativaParecer.trim() !== "";

    const [alertaDivergencia, setAlertaDivergencia] = useState(false);
    const [alertaFinalizacaoPedido, setAlertaFinalizacaoPedido] = useState(false);
    const [fornecedorUau, setFornecedorUau] = useState("");
    const [valorUau, setValorUau] = useState("");

    const handleAprovarClick = async () => {
        if (finalizarPedidoSup3) {
            await handleFinalizarPedido();
        } else if (permitirFinalizacao) {
            try {

                if(!validaContratoPresente())
                    return;

                const response = await verificaDivergenciaContratual();

                if (!response) {
                    console.error("❌ Erro: `data` não está presente na resposta.", response);
                    return;
                }

                if (response.divergente) {
                    setAlertaDivergencia(true);
                    setFornecedorUau(response.nomeFornecedor);
                    setValorUau(response.valorUAU);
                } else {
                    setAlertaFinalizacaoPedido(true);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            await handleMovimentarPedido(true, false);
        }
    };

    return (
        <>
            {exibirCampoAprovacao && (
                <>
                    {showAlert && <AlertaBootstrap mensagem={alertMessage} tipo={alertType} />}
                    {permitirFinalizacao && alertaDivergencia && (
                        <AlertDialog
                            titulo="Envio para aprovação de justificativa"
                            mensagem={`Foi identificado uma divergência entre os sistemas SCT e UAU: Fornecedor UAU: ${fornecedorUau}/ Valor UAU: ${valorUau}`}
                            opcao1="Rever processo/justificativa"
                            opcao2="OK"
                            open={alertaDivergencia}
                            handleOpcao1={() => setAlertaDivergencia(false)}
                            handleOpcao2={async () => await handleFinalizarPedido()}
                            duasOpcoes={true} />
                    )}
                    {permitirFinalizacao && alertaFinalizacaoPedido && (
                        <AlertDialog
                            titulo="Pedido finalizado"
                            mensagem="Não foram encontradas divergências entre os sistemas SCT e UAU."
                            opcao1="None"
                            opcao2="OK"
                            open={alertaFinalizacaoPedido}
                            handleOpcao1={() => { }}
                            handleOpcao2={async () => await handleFinalizarPedido()}
                            duasOpcoes={false} />
                    )}
                    <h5>
                        {permitirFinalizacao
                            ? "Justificativa"
                            : "Parecer da aprovação ou reprovação"}
                    </h5>
                    {permitirFinalizacao && (
                        <div className="form-floating w-25 mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="ContratoUAU"
                                value={contratoUAU}
                                onChange={handleContratoUAUChange}
                            />
                            <label
                                htmlFor="ContratoUAU"
                                className="lh-1 fs-6 text-primary text-wrap"
                            >
                                Número contrato UAU <span className="text-danger">*</span>
                            </label>
                        </div>
                    )}
                    <div className="form-floating mt-3">
                        <textarea
                            className="form-control w-100 h-100"
                            id="justificativaParecerAprovacao"
                            value={justificativaParecer}
                            onChange={handleParecerChange}
                        ></textarea>
                        <label
                            className="lh-1 fs-6 text-primary text-wrap"
                            htmlFor="justificativaParecerAprovacao"
                        >
                            {permitirFinalizacao
                                ? "Justificativa"
                                : "Parecer Aprovar/Reprovar"}
                            <span className="text-danger">*</span>
                        </label>
                    </div>
                    <div className="d-flex justify-content-between">
                        {temPermissao ? (
                            <>
                                <div className="d-flex gap-4 w-100">
                                    <button
                                        className="btn btn-outline-success"
                                        disabled={!isParecerValido}
                                        onClick={handleAprovarClick}
                                    >
                                        Submeter MDE
                                    </button>
                                    {(responsavelPelaEtapa != "Suprimentos 1" && !temPermissao) && (
                                        <button
                                            className="btn btn-outline-danger"
                                            onClick={() => handleMovimentarPedido(false, false)}
                                            disabled={!isParecerValido}
                                        >
                                            Reprovar
                                        </button>
                                    )}
                                    <button
                                        className="btn btn-outline-danger"
                                        disabled={!isParecerValido}
                                        onClick={() => handleMovimentarPedido(false, false)}
                                    >
                                        Editar data assinatura contrato
                                    </button>
                                    <button
                                        className="btn btn-outline-warning ms-auto"
                                        disabled={!isParecerValido}
                                        onClick={handleCancelarPedido}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="d-flex gap-4 w-100">
                                {!permitirFinalizacao && (
                                    <button
                                        className="btn btn-outline-success"
                                        onClick={handleAprovarClick}
                                        disabled={!isParecerValido}
                                    >
                                        Aprovar
                                    </button>
                                )}
                                {permitirFinalizacao && (
                                    <button
                                        className="btn btn-outline-success"
                                        disabled={!isFinalizarValido}
                                        onClick={handleAprovarClick}
                                    >
                                        Finalizar pedido
                                    </button>
                                )}
                                {(temPermissaoSuprimentos3 || temPermissaoSuprimentos4) && (
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => handleMovimentarPedido(false, true)}
                                        disabled={!isParecerValido}
                                    >
                                        Esclarecer dúvida
                                    </button>
                                )}
                                {responsavelPelaEtapa != "Solicitante" && (
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => handleMovimentarPedido(false, false)}
                                        disabled={!isParecerValido}
                                    >
                                        Reprovar
                                    </button>
                                )}
                                {permitirReinicio && (
                                <button
                                className="btn btn-outline-warning ms"
                                disabled={!isParecerValido}
                                onClick={handleReinicarPedido}
                                >
                                    Reiniciar fluxo
                                </button>
                                )}
                                <button
                                    className="btn btn-outline-warning ms-auto"
                                    disabled={!isParecerValido}
                                    onClick={handleCancelarPedido}
                                >
                                    Cancelar
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
            {permitirReabertura && (
                <button
                className="btn btn-outline-warning ms-auto"
                onClick={handleReaberturaPedido}
                >
                    Reabrir pedido
                </button>
            )}
            <ModalConfirmacaoCancelar
                show={showModalCancelar}
                onClose={() => setShowModalCancelar(false)}
                onConfirm={confirmarCancelamento}
            />
            <ModalConfirmacaoReiniciar
                show={showModalReiniciar}
                onClose={() => setShowModalReiniciar(false)}
                onConfirm={confirmarReinicio}
            />
            <ModalConfirmacaoReabertura
                show={showModalReabertura}
                onClose={() => setShowModalReabertura(false)}
                onConfirm={confirmarReabertura}
            />
        </>
    );
};

export default ParecerAprovacao;
