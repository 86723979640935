import { Remove } from '@mui/icons-material';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { removeOrcamentoSelecionado, updateOrcamento } from '../../redux/pedido/sliceViewModelEntrada';
import { formatarValor4CasasDecimais } from '../../Utils/FormatarMoedaBrasileira';
import { DelayInput } from '../DelayInput/inputObservacoes';
import './style.scss';

const TabelaOrcamentos: React.FC = () => {
  const dispatch = useDispatch();
  const orcamentosSelecionados = useSelector((state: any) => state.pedidoSliceViewModelEntrada.orcamentos.orcamentoItens);
  const [rows, setRows] = useState<any[]>([]);
  const desabilitadoTabela = useSelector((state: any) => state.modeSlice.desabilitadoTabela);
  const mode = useSelector((state: any) => state.modeSlice.mode);
  const [somaTotalAjustado, setSomaTotalAjustado] = useState<string>('');
  const orcamentosExistentes = useSelector((state: any) => state.pedidoSliceViewModelSaida?.orcamentos?.orcamentoItens);

  useEffect(() => {
    if (mode === "view") {
      const itensAtivos = orcamentosSelecionados.filter((item: { itemOrcamento: string; }) =>
        (item.itemOrcamento.match(/\*/g) || []).length < 2
      );
      const somaTotal = itensAtivos.reduce((acc: number, item: { valorAjustado: any; }) =>
        acc + parseFloat(item?.valorAjustado || 0), 0);
      const valorFormatado = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        maximumFractionDigits: 4,
      }).format(somaTotal);
      setSomaTotalAjustado(valorFormatado);
    }
  }, [orcamentosSelecionados, mode]);

  useEffect(() => {
    if (orcamentosSelecionados && Array.isArray(orcamentosSelecionados)) {
      const novosRows = orcamentosSelecionados.map((orcamento: any) => ({
        id: orcamento.codigoOrcamento,
        codigoOrcamento: orcamento.codigoOrcamento,
        itemOrcamento: orcamento.itemOrcamento,
        unidadeOrcada: orcamento.unidadeOrcada || '',
        quantidadeOrcada: orcamento.quantidadeOrcada || '',
        valorUnitario: orcamento.valorUnitario || 0,
        valorOrcamento: orcamento.valorOrcamento || 0,
        valorAjustado: orcamento.valorAjustado || 0,
        observacoes: orcamento.observacoes || ''
      }));
      setRows(novosRows);
    }
  }, [orcamentosSelecionados]);

  const handleInputChange = (id: string, field: string, value: any) => {
    dispatch(updateOrcamento({ id, field, value }));
    dispatch(updateOrcamento({ id, field: 'ativo', value: false }));

    if (field === 'quantidadeOrcada' || field === 'valorUnitario') {
      const row = rows.find(row => row.id === id);
      if (row) {
        const quantidadeOrcada = field === 'quantidadeOrcada' ? parseFloat(value as string) : row.quantidadeOrcada;
        const valorUnitario = field === 'valorUnitario' ? parseFloat(value as string) : row.valorUnitario;
        const valorOrcamentoAtualizado = quantidadeOrcada * valorUnitario;
        dispatch(updateOrcamento({ id, field: 'valorOrcamento', value: valorOrcamentoAtualizado }));
        dispatch(updateOrcamento({ id, field: 'valorAjustado', value: valorOrcamentoAtualizado }));
      }
    }
  };

  const handleValorUnitarioChange = (
    id: string,
    values: { formattedValue: string; value: string; floatValue: number | undefined }
  ) => {
    const { floatValue } = values;
    const valorNumerico = floatValue ?? 0;
    handleInputChange(id, 'valorUnitario', floatValue);
    const quantidadeOrcada = rows.find(row => row.id === id)?.quantidadeOrcada || 0;
    const valorOrcamentoAtualizado = quantidadeOrcada * valorNumerico;
    handleInputChange(id, 'valorOrcamento', valorOrcamentoAtualizado);
    handleInputChange(id, 'valorAjustado', valorOrcamentoAtualizado);
  };

  const columns = [
    { field: 'codigoOrcamento', header: 'Código do orçamento', width: 130, align: 'center' as const },
    { field: 'itemOrcamento', header: 'Item orçamento', width: 200 },
    { field: 'unidadeOrcada', header: 'Unidade orçada', width: 125 },
    { field: 'quantidadeOrcada', header: 'Quantidade orçada', width: 140 },
    { field: 'valorUnitario', header: 'Vlr unitário do orçamento (em reais)', width: 150 },
    { field: 'valorOrcamento', header: 'Valor orçamento', width: 140 },
    { field: 'valorAjustado', header: 'Valor ajustado', width: 120 },
    { field: 'observacoes', header: 'Observações', width: 130 },
    { field: 'acao', header: 'Ação', width: 60, align: 'center' as const },
  ];

  const handleRemoverOrcamento = (id: string) => {
    const orcamentoAntigo = orcamentosExistentes?.some(
      (orcamento: { codigoOrcamento: string }) => orcamento.codigoOrcamento === id
    );

    if (mode === "create" || (mode === "view" && !orcamentoAntigo)) {
      dispatch(removeOrcamentoSelecionado(id));
    } else if (mode === "view" && orcamentoAntigo) {
      const item = orcamentosSelecionados.find(
        (orcamento: { codigoOrcamento: string; }) => orcamento.codigoOrcamento === id
      );
      if (item) {
        dispatch(updateOrcamento({
          id,
          field: 'itemOrcamento',
          value: (item.itemOrcamento.match(/\*/g) || []).length === 1
            ? `*${item.itemOrcamento}`
            : `**${item.itemOrcamento}`
        }));
      }
    }
  };


  return (
    <div className='tableOrcamento'>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="Resumo Financeiro">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.field} style={{ width: column.width }}>
                  {column.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              const asteriscos = (row.itemOrcamento.match(/\*/g) || []).length;
              const linhaClasse = asteriscos === 2 ? 'linha-vermelha' : (asteriscos === 1 ? 'linha-verde' : '');
              const tamanhoObservacao = row.observacoes.length;
              const caracteresRestantes = 1000 - tamanhoObservacao;
              return (
                <TableRow key={row.id}>
                  <TableCell align='center' className={linhaClasse}>{row.codigoOrcamento || '-'}</TableCell>
                  <TableCell className={linhaClasse}>{row.itemOrcamento || '-'}</TableCell>
                  <TableCell className={linhaClasse}>
                    <DelayInput
                      disabled={desabilitadoTabela || asteriscos === 2}
                      defaultValue={row.unidadeOrcada}
                      delayTimeout={500}
                      handleDelay={(e) => handleInputChange(row.id, 'unidadeOrcada', e)}
                      itemId={row.id}
                    />
                  </TableCell>
                  <TableCell className={linhaClasse}>
                    <TextField
                      disabled={desabilitadoTabela || asteriscos === 2}
                      type="number"
                      className="inputTabela"
                      value={row.quantidadeOrcada}
                      onChange={(e) => handleInputChange(row.id, 'quantidadeOrcada', parseFloat(e.target.value))}
                    />
                  </TableCell>
                  <TableCell className={linhaClasse}>
                    <NumericFormat
                      className={`inputNumericoTabela ${linhaClasse}`}
                      value={row.valorUnitario || ""}
                      onValueChange={(values) => handleValorUnitarioChange(row.id, values)}
                      decimalScale={4}
                      fixedDecimalScale
                      decimalSeparator=','
                      thousandSeparator='.'
                      prefix='R$ '
                      placeholder='R$ 0,00'
                      disabled={desabilitadoTabela || asteriscos === 2}
                    />
                  </TableCell>
                  <TableCell align="left" className={linhaClasse}>
                    {formatarValor4CasasDecimais(row.valorOrcamento) || '-'}
                  </TableCell>
                  <TableCell align="left" className={linhaClasse}>
                    {formatarValor4CasasDecimais(row.valorAjustado) || '-'}
                  </TableCell>
                  <TableCell className={linhaClasse} sx={{ whiteSpace: "normal", p: 1 }}>
                    <TextField
                      disabled={desabilitadoTabela || asteriscos === 2}
                      defaultValue={row.observacoes}
                      multiline
                      maxRows={6}
                      className="inputTabela"
                      variant="outlined"
                      sx={{
                        "& .MuiInputBase-root": {
                          alignItems: "start",
                          padding: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          height: "auto",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            boxShadow: caracteresRestantes === 0 ? "0 0 0 0.1rem rgba(247, 0, 0, 0.83)" : "0 0 0 0.2rem rgba(0, 123, 255, 0.25)",
                          },
                        },
                      }}
                      onChange={(e) => handleInputChange(row.id, 'observacoes', e.target.value)}
                      slotProps={{
                        htmlInput: { maxLength: 1000 }
                      }}
                    />
                    {caracteresRestantes <= 100 && (
                      <Typography
                        sx={{
                          textAlign: "right",
                          color: "red",
                          marginTop: "5px",
                          fontSize: "13px",
                        }}
                      >
                        {caracteresRestantes === 0
                          ? "Número de caracteres excedido"
                          : `${caracteresRestantes} caracteres restantes`}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={() => handleRemoverOrcamento(row.id)}
                      disabled={desabilitadoTabela || asteriscos === 2}
                    >
                      <Remove style={{ fontSize: '15px' }} />
                    </button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {mode === 'view' && (
        <div className="w-25">
          <label htmlFor="valorOrcamentoAjustado">
            Valor total do orçamento ajustado:
          </label>
          <input
            className="form-control"
            id="valorOrcamentoAjustado"
            type="text"
            placeholder="R$ 0,00"
            aria-label="Disabled input example"
            disabled
            value={(somaTotalAjustado)}
          />
        </div>
      )}
    </div>
  );
};

export default TabelaOrcamentos;