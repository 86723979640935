import { Download, Remove } from '@mui/icons-material';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Anexo, AnexosProps, tipoDeAnexo } from "../../Interface/IAnexos";
import { getListaTipoAnexo } from "../../Repository/domain/Pedidos";
import AlertaBootstrap from "../Alertas";

const Anexos: React.FC<AnexosProps> = ({
    anexosIniciais = [],
    onAnexosChange,
    setAnexoAtualizado,
    setAnexoRemovido,
    historicoPedido
}) => {
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const usuarioLogado = localStorage.getItem('codigoUsuario');
    const [listaDeAnexos, setListaDeAnexos] = useState<tipoDeAnexo[]>([]);
    const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] = useState<string>("");
    const [nomeArquivoDuplicado, setNomeArquivoDuplicado] = useState<string>("");
    const [modalAlert, setModalAlert] = useState<boolean>(false);
    const [anexos, setAnexos] = useState<Anexo[]>([]);
    const [dragOver, setDragOver] = useState<boolean>(false);

    const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
    const acao = pedidoExistente.acao;
    const inputFileRef = useRef<HTMLInputElement>(null);

    const memoizedAnexosIniciais = useMemo(
        () => anexosIniciais,
        [anexosIniciais]
    );

    useEffect(() => {
        const fetchAnexos = async () => {
            try {
                const anexos: any =
                    (await getListaTipoAnexo()) as unknown as tipoDeAnexo;
                setListaDeAnexos(anexos);
            } catch (error) {
                alert("Erro ao listar opções de anexo:" + error);
            }
        };
        fetchAnexos();
    }, []);

    useEffect(() => {
        if (mode === "view" && memoizedAnexosIniciais) {
            const formattedAnexos = memoizedAnexosIniciais.map((anexo, index) => ({
                id: index,
                tipoDocumento: anexo.tipoArquivo,
                nomeArquivo: anexo.nomeArquivo,
                url: anexo.url,
                usuarioCadastro: anexo.usuarioCadastro,
                nomeUsuarioCadastro: anexo.nomeUsuarioCadastro,
                dataCadastro: anexo.dataCadastro,
            }));
            setAnexos(formattedAnexos);
        }
    }, [mode, memoizedAnexosIniciais]);

    const handleTipoDocumentoChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setTipoDocumentoSelecionado(event.target.value);
    };

    const processarArquivo = (file: File) => {
        if (!tipoDocumentoSelecionado) return;

        if (anexos.some(anexo => anexo.nomeArquivo === file.name && anexo.tipoDocumento === tipoDocumentoSelecionado)) {
            setNomeArquivoDuplicado(file.name);
            setModalAlert(true);
            setTipoDocumentoSelecionado("");
            return;
        };

        const novoAnexo = {
            id: new Date().getTime(),
            tipoDocumento: tipoDocumentoSelecionado,
            nomeArquivo: file.name,
            arquivo: file,
        };

        const novosAnexos = [...anexos, novoAnexo];
        setAnexos(novosAnexos);
        setTipoDocumentoSelecionado("");

        if (mode === "create") {
            onAnexosChange?.(novosAnexos);
        }

    };

    const handleArquivoSelecionado = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files && event.target.files[0]) {
            processarArquivo(event.target.files[0]);
            event.target.value = "";
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);

        if (!tipoDocumentoSelecionado) return;

        const file = event.dataTransfer.files[0];
        if (file) {
            processarArquivo(file);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragOver(false);
    };

    const removerAnexo = async (id: number) => {
        const novosAnexos = anexos.filter((anexo) => anexo.id !== id);
        const anexoRemovido = anexos.find((anexo) => anexo.id === id);
        if (setAnexoAtualizado) {
            setAnexoAtualizado(novosAnexos)
        }
        setAnexos(novosAnexos);
        if (anexoRemovido && setAnexoRemovido && mode == 'view' && usuarioLogado?.toLowerCase() === anexoRemovido.usuarioCadastro?.toLowerCase()) {
            setAnexoRemovido((prev) => [...prev, anexoRemovido]);
        }
        // Chame o callback apenas se o modo for "create"
        if (mode === "create") {
            onAnexosChange?.(novosAnexos);
        }
    };

    useEffect(() => {
        if (anexos.some(anexo => anexo.arquivo)) {
            setAnexoAtualizado?.(anexos)
        }
    }, [anexos]);


    const botaoRemoverDesabilitado = (anexo: Anexo) => {
        const anexoInicial = anexosIniciais.find(
            (anexoInicial) => anexoInicial.nomeArquivo === anexo.nomeArquivo && anexoInicial.tipoArquivo === anexo.tipoDocumento
        );
        if (historicoPedido && historicoPedido.some(h => h.etapa === "Cotação da solicitação do serviço" && h.aprovacao === "Aprovado")) {
            return true;
        }
        if (anexoInicial && acao) {
            return !(anexo.usuarioCadastro && anexo.usuarioCadastro.toLowerCase() === usuarioLogado?.toLowerCase());
        }
        if (acao === false) {
            return true;
        }
        return false;
    };

    return (
        <div>
            {modalAlert && (<AlertaBootstrap mensagem={`O arquivo ${nomeArquivoDuplicado} já está anexado`} tipo="danger" onFechar={() => setModalAlert(false)} />)}
            {((mode === 'create') || (anexos.length > 0) || (acao)) && (
                <>
                    <hr />
                    <h5>Anexos</h5>
                    <br />
                </>
            )}
            {((mode === 'view' && acao) || (mode === 'create')) && (
                <div className="d-flex gap-5 justify-content-center px-4">
                    <div className="form-floating mb-3 w-50 ">
                        <select
                            className="form-select"
                            id="TipoDocumento"
                            aria-label="Floating label select example"
                            value={tipoDocumentoSelecionado}
                            onChange={handleTipoDocumentoChange}
                        >
                            <option value="">Selecione...</option>
                            {listaDeAnexos
                                .slice()
                                .map((anexo) => (
                                    <option key={anexo.id} value={anexo.tipoAnexo}>
                                        {anexo.tipoAnexo}
                                    </option>
                                ))}
                        </select>
                        <label className="text-primary" htmlFor="TipoDocumento" style={{ zIndex: 1 }}>
                            Tipo de Documento
                        </label>
                    </div>
                    <div className={`form-floating mb-3 w-50 d-flex align-items-center justify-content-center border rounded ${dragOver ? "border-primary bg-light" : "border-secondary"
                        }`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <div className="formulario" style={{ width: "100%", height: "100%" }}>
                            <input
                                type="file"
                                id="CampoArquivos"
                                onChange={handleArquivoSelecionado}
                                disabled={!tipoDocumentoSelecionado}
                                ref={inputFileRef}
                                style={{ display: "none" }}
                            />
                            <button
                                type="button"
                                className="btn btn-primary w-100 h-100"
                                onClick={() => inputFileRef.current?.click()}
                                disabled={!tipoDocumentoSelecionado}
                            >
                                {dragOver ? "Solte o arquivo aqui" : "Escolher arquivo ou arrastar"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {((mode === 'view' && anexosIniciais.length > 0) || (mode === 'create') || ((mode === 'view' && acao))) && (
                <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
                    <thead>
                        <tr className="table-primary text-center">
                            <th scope="col">Tipo de documento</th>
                            <th scope="col">Nome do arquivo</th>
                            {mode !== "create" && (
                                <>
                                    <th scope="col">Usuario Cadastrante</th>
                                    <th scope="col">Data do Cadastro</th>
                                </>
                            )}
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {anexos.map((anexo) => (
                            <tr key={anexo.id}>
                                <td align="left">{anexo.tipoDocumento}</td>
                                <td align="left">{anexo.nomeArquivo}</td>
                                {mode !== "create" && (
                                    <>
                                        <td align="left">{anexo.nomeUsuarioCadastro || 'XXXXXX'}</td>
                                        <td align="left">{anexo.dataCadastro && anexo.dataCadastro.startsWith("01/01/0001 00:00") ? "XX/XX/XXXX XX:XX" : anexo.dataCadastro}</td>
                                    </>
                                )}
                                <td>
                                    <div className="d-flex gap-3 justify-content-center">
                                        {mode === "view" && (
                                            <a href={anexo.url} target="_blank" download>
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-primary">
                                                    <Download style={{ fontSize: '18px' }} />
                                                </button>
                                            </a>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => removerAnexo(anexo.id)}
                                            disabled={botaoRemoverDesabilitado(anexo)}
                                        >
                                            <Remove style={{ fontSize: '18px' }} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Anexos;
