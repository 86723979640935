import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoadingProps } from "../Interface/ILoading";
import AcompanharPedido from "../Pages/AcompanharPedido";
import AprovarPedido from "../Pages/AprovarPedido";
import CriarPedido from "../Pages/CriarPedido";
import Login from "../Pages/Login";

const RoutesComponent: React.FC<LoadingProps> = ({ setLoading }) => {

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login/:tela/:tokenCognito/:tokenGA/:accessToken"
          element={<Login setLoading={setLoading} />}
        />
        <Route
          path="/solicitar/"
          element={<CriarPedido setLoading={setLoading} />}
        />
        <Route
          path="/pedidos/"
          element={<AcompanharPedido setLoading={setLoading} />}
        />
        <Route
          path="/visualizarPedido/:numeroPedido"
          element={<CriarPedido setLoading={setLoading} />}
        />
        <Route
          path="/aprovacoes/:numeroPedido"
          element={<AprovarPedido setLoading={setLoading} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
