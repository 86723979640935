import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    mode: 'create',
    desabilitado: false,
    desabilitadoTabela: false,
    loadingCriarPedido: false,
    desabilitadoSolicitante: false,
}

const modeSlice = createSlice({
    name: "mode",
    initialState,
    reducers: {
        setMode(state, action: PayloadAction<any["mode"]>) {
            state.mode = action.payload;
        },
        setDesabilitado(state, action: PayloadAction<any["desabilitado"]>) {
            state.desabilitado = action.payload;
        },
        setDesabilitadoTabela(state, action: PayloadAction<any["desabilitadoTabela"]>) {
            state.desabilitadoTabela = action.payload;
        },
        setLoadingCriarPedido(state, action: PayloadAction<any["loadingCriarPedido"]>) {
            state.loadingCriarPedido = action.payload;
        },
        setDesabilitadoSolicitante(state, action: PayloadAction<any["desabilitadoSolicitante"]>) {
            state.desabilitadoSolicitante = action.payload;
        }
    }
});

export const { setMode, setDesabilitado, setDesabilitadoTabela, setDesabilitadoSolicitante, setLoadingCriarPedido } = modeSlice.actions;
export default modeSlice.reducer;
