import { KeyboardArrowDown } from '@mui/icons-material';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormData } from "../../Hooks/useFormData";
import useTipoDeSolicitacao from "../../Hooks/useTipoDeSolicitacao";
import { FormularioProps } from "../../Interface/IFormulario";
import { setItem } from "../../redux/pedido/sliceViewModelEntrada";
import { getContratacoesIniciaisPorFornecedor } from "../../Repository/domain/Servicos";
import { formatarMoedaBrasileira } from "../../Utils/FormatarMoedaBrasileira";
import { CustomTooltip } from "../CustomTooltip";
import "./style.scss";

const Formulario: React.FC<FormularioProps> = ({ servicos, obrasPorMatricula }) => {
    const dispatch = useDispatch();
    const tipoDeSolicitacao = useTipoDeSolicitacao();
    const desabilitado = useSelector((state: any) => state.modeSlice.desabilitado);
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const [inputValue, setInputValue] = useState("");
    const novoPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const desabilitadoSolicitante = useSelector((state: any) => state.modeSlice.desabilitadoSolicitante);
    const [obras, setObras] = useState<any[]>([])

    useEffect(() => {
        if (obrasPorMatricula)
            setObras(obrasPorMatricula.map(
                (obra: { codigoObra: string; codigoEmpresa: number; nomeObra: string }) => ({
                    ...obra,
                    label: `${obra.codigoEmpresa} - ${obra.codigoObra} - ${obra.nomeObra}`,
                })
            ));

    }, [obrasPorMatricula]);

    function filterObrasByInput(): readonly any[] {
        if (inputValue.length <= 2)
            return [];

        const input = inputValue.toLowerCase();

        return obras.filter(
            (obra: { label: string }) =>
                obra?.label?.toLowerCase().includes(input)
        ) ?? [];
    }

    const handleValorPagarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const valorPagar = Number(value.replace(/[R$.,\s]/g, "")) / 100;
        dispatch(setItem({ ...novoPedido, [name]: valorPagar }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setItem({ ...novoPedido, [name]: value }));

        if (name === "processoAnterior" && value.trim() !== "") {
            setErroContrato(false);
        }
    };

    const { fornecedores } = useFormData(
        novoPedido?.codigoEmpresa.toString(),
        novoPedido?.codigoObra
    );

    const [contratacoes, setContratacoes] = useState<{ id: number }[]>([]);
    const fornecedorSelecionado = novoPedido.fornecedor;

    const [erroContrato, setErroContrato] = useState(false);
    const isContratoInicialHabilitado = !!novoPedido?.fornecedor;

    useEffect(() => {
        if (!fornecedorSelecionado) return;

        const fetchContratacoes = async () => {
            if (fornecedorSelecionado) {
                try {
                    const response = await getContratacoesIniciaisPorFornecedor(fornecedorSelecionado);

                    const contratacoesMapeadas = response.map((id: number) => ({ id }));

                    setContratacoes(contratacoesMapeadas);
                } catch (error) {
                    console.error("Erro ao buscar contratações:", error);
                }
            }
        };

        fetchContratacoes();

    }, [fornecedorSelecionado]);

    return (
        <div className="formulario">
            <div className="d-flex gap-4">
                <TextField
                    className="inputForm"
                    label="Empresa"
                    name="codigoEmpresa"
                    disabled
                    value={novoPedido.codigoObra == '' ? '' :
                        `${novoPedido.codigoEmpresa} - ${novoPedido.razaoSocial}`
                    }
                />
                <Autocomplete
                    className="inputForm"
                    options={filterObrasByInput()}
                    value={
                        obras.find(
                            (option: { codigoObra: any; codigoEmpresa: any }) =>
                                option.codigoObra === novoPedido.codigoObra &&
                                option.codigoEmpresa === novoPedido.codigoEmpresa
                        ) || null
                    }
                    getOptionLabel={(option) =>
                        option.codigoObra === ""
                            ? ""
                            : option.label
                    }
                    inputValue={inputValue}
                    onInputChange={(_event, newInputValue) =>
                        setInputValue(newInputValue)
                    }
                    isOptionEqualToValue={(option, value) =>
                        option.codigoObra === value.codigoObra &&
                        option.codigoEmpresa === novoPedido.codigoEmpresa
                    }
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label={
                                <>
                                    Obra <span style={{ color: "red" }}>*</span>
                                </>
                            }
                            slotProps={{ inputLabel: { shrink: false } }}
                        />
                    )}
                    popupIcon={null}
                    noOptionsText={
                        <>
                            {inputValue.length <= 2 ? "Digite o número obra" : "Nenhuma opção"}
                            <strong style={{ color: "red" }}>...</strong>
                        </>
                    }
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = {
                                ...newValue,
                                nomeEmpreendimento: newValue.nomeObra,
                                razaoSocial: newValue.nomeEmpresa,
                            };
                            dispatch(setItem(mappedValue));
                        }
                    }}
                    disabled={desabilitadoSolicitante}
                />
            </div>
            <div className="d-flex gap-4">
                <Autocomplete
                    className="inputForm"
                    options={tipoDeSolicitacao}
                    value={tipoDeSolicitacao.find((option: { id: number, descricao: string; }) => option.descricao === novoPedido.descricaoTipoSolicitacao) || null}
                    isOptionEqualToValue={(option: any, value) => option.id === value.id}
                    getOptionLabel={(option) => option.descricao || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = {
                                ...novoPedido,
                                tipoSolicitacao: newValue.id,
                                descricaoTipoSolicitacao: newValue.descricao,
                            };
                            dispatch(setItem(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label={<>Tipo de solicitação <span style={{ color: 'red' }}>*</span></>}
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<KeyboardArrowDown style={{ fontSize: '18px', marginRight: '5px' }} />}
                    noOptionsText="Nenhuma opção"
                    disabled={desabilitado && desabilitadoSolicitante}
                />
                <CustomTooltip title={novoPedido?.descricaoServico}>
                    <Autocomplete
                        className="inputForm"
                        options={servicos}
                        value={servicos.find((option: { id: any; }) => option.id === novoPedido.idServico) || null}
                        isOptionEqualToValue={(option: any, value) => option.id === value.id}
                        getOptionLabel={(option) => option.nomeServico || ""}
                        onChange={(_event, newValue) => {
                            if (newValue) {
                                const mappedValue = {
                                    ...novoPedido,
                                    idServico: newValue.id,
                                    descricaoServico: newValue.nomeServico,
                                    tipoServico: newValue.nomeServico,
                                    codigoServicoBI: newValue.codigoServicoBI,
                                };
                                dispatch(setItem(mappedValue));
                            }
                        }}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                label={<>Tipo de serviço <span style={{ color: 'red' }}>*</span></>}
                                slotProps={{ inputLabel: { shrink: false, } }}
                            />
                        )}
                        popupIcon={<KeyboardArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                        />}
                        noOptionsText="Nenhuma opção"
                        disabled={desabilitado && desabilitadoSolicitante}
                    />
                </CustomTooltip>
                <TextField
                    className="inputForm"
                    label="Estado"
                    disabled
                    value={novoPedido?.estado}
                />
                <TextField
                    className="inputForm"
                    label="Data do pedido"
                    disabled
                    value={novoPedido?.dataPedido}
                    type="date"
                    slotProps={{ inputLabel: { shrink: false, } }}
                />
            </div>
            {novoPedido?.descricaoTipoSolicitacao !== "Contratação Inicial" &&
                novoPedido?.descricaoTipoSolicitacao !== "" &&
                novoPedido !== null && (
                    <>
                        <div className="d-flex gap-4">
                            <CustomTooltip title={novoPedido?.fornecedor}>
                                <Autocomplete
                                    className="inputForm"
                                    id="Fornecedor"
                                    options={fornecedores || []}
                                    value={
                                        fornecedores.find(
                                            (option: { nomeFornecedor: any }) =>
                                                option.nomeFornecedor === novoPedido.fornecedor
                                        ) || null
                                    }
                                    isOptionEqualToValue={(option: any, value) =>
                                        option.codigoFornecedor === value.codigoFornecedor
                                    }
                                    getOptionLabel={(option) => option.nomeFornecedor || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Fornecedor"
                                            slotProps={{ inputLabel: { shrink: false } }}
                                        />
                                    )}
                                    popupIcon={
                                        <KeyboardArrowDown style={{ fontSize: "18px", marginRight: "5px" }} />
                                    }
                                    noOptionsText="Nenhuma opção"
                                    onChange={(_event, newValue) => {
                                        if (newValue) {
                                            dispatch(
                                                setItem({ ...novoPedido, fornecedor: newValue.nomeFornecedor })
                                            );
                                        }
                                    }}
                                    disabled={desabilitado && desabilitadoSolicitante}
                                />
                            </CustomTooltip>

                            <Autocomplete
                                className="inputForm"
                                options={contratacoes}
                                getOptionLabel={(option) => option.id.toString()}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                value={
                                    contratacoes.find((item) => item.id === novoPedido?.processoAnterior) ||
                                    null
                                }
                                onChange={(_, newValue) => {
                                    if (newValue) {
                                        dispatch(setItem({ ...novoPedido, processoAnterior: newValue.id }));
                                        setErroContrato(false);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={
                                            <>
                                                N° da contratação inicial <span style={{ color: "red" }}>*</span>
                                            </>
                                        }
                                        slotProps={{ inputLabel: { shrink: false } }}
                                        error={erroContrato}
                                        helperText={erroContrato ? "Selecione uma contratação inicial" : ""}
                                    />
                                )}
                                noOptionsText="Nenhuma opção encontrada"
                                popupIcon={
                                    <KeyboardArrowDown style={{ fontSize: "18px", marginRight: "5px" }} />
                                }
                                disabled={!isContratoInicialHabilitado}
                            />

                            <TextField
                                className="inputForm"
                                label="N° do contrato UAU"
                                type="number"
                                name="contratoUAU"
                                value={novoPedido?.contratoUAU}
                                onChange={handleInputChange}
                                slotProps={{ inputLabel: { shrink: false, } }}
                                disabled={desabilitado && desabilitadoSolicitante}
                            />

                            <FormControl className="inputForm" fullWidth>
                                <InputLabel>Haverá distrato?</InputLabel>
                                <Select
                                    displayEmpty
                                    IconComponent={() => (
                                        <KeyboardArrowDown style={{ fontSize: '20px', marginRight: '15px', marginBottom: '20px', color: 'grey' }} />
                                    )}
                                    name="distrato"
                                    onChange={(event: SelectChangeEvent<unknown>) => {
                                        dispatch(setItem({ ...novoPedido, distrato: event.target.value === 1 }));
                                    }}
                                    value={novoPedido?.distrato === true ? 1 : 2}
                                    disabled={desabilitado && desabilitadoSolicitante}
                                >
                                    <MenuItem value={1}>Sim</MenuItem>
                                    <MenuItem value={2}>Não</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {novoPedido?.distrato === true && (
                            <div className="w-25">
                                <TextField
                                    className="inputForm"
                                    label={<>Qual o valor? <span style={{ color: 'red' }}>*</span></>}
                                    value={formatarMoedaBrasileira(novoPedido.valorPagar)}
                                    slotProps={{ inputLabel: { shrink: false, } }}
                                    name='valorPagar'
                                    onChange={handleValorPagarChange}
                                    disabled={desabilitado && desabilitadoSolicitante}
                                />
                            </div>
                        )}
                    </>
                )
            }
            <hr />
        </div >
    )
}

export default Formulario;