import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDetalhePedidoViewModelSaida } from '../Interfaces/IPedidoViewModelSaida';

const initialState = {};

const mapSaidaToEntrada = (saida: IDetalhePedidoViewModelSaida) => ({
    cnpj: saida.pedido?.cnpj ?? null,
    codigoEmpresa: saida.pedido?.codigoEmpresa ?? null,
    codigoObra: saida.pedido?.codigoObra ?? null,
    codigoPedido: saida.pedido?.codigoPedido ?? null,
    condicoesPagamentos: saida.pedido?.condicoesPagamentos ?? null,
    contratoUAU: saida.pedido?.contratoUAU ?? null,
    criterioMedicao: saida.pedido?.criterioMedicao ?? null,
    dataAssinatura: saida.pedido?.dataAssinatura ?? null,
    dataFimExecucao: saida.pedido?.dataFimExecucao ?? null,
    dataInicioExecucao: saida.pedido?.dataInicioExecucao ?? null,
    descricaoAditivo: saida.pedido?.descricaoAditivo ?? null,
    descricaoServico: saida.pedido?.descricaoServico ?? null,
    descricaoTipoSolicitacao: saida.pedido?.tipoSolicitacao ?? null,
    detalhamento: saida.pedido?.detalhamento ?? null,
    distrato: saida.pedido?.distrato ?? null,
    especificacaoMateriais: saida.pedido?.especificacaoMateriais ?? null,
    estado: saida.pedido?.estado ?? null,
    flagEmergencial: saida.pedido?.flagEmergencial ?? null,
    fornecedor: saida.pedido?.fornecedor ?? null,
    fornecedoresIndicados: saida.pedido?.fornecedoresIndicados ?? null,
    fornecedores: saida.pedido?.fornecedores ?? null,
    idEmpresaObra: saida.pedido?.idEmpresaObra ?? null,
    idServico: saida.pedido?.tipoServico ?? null,
    justificativaEmergencial: saida.pedido?.justificativaEmergencial ?? null,
    justificativaPedido: saida.pedido?.justificativaPedido ?? null,
    justificativaReabertura: saida.pedido?.justificativaReabertura ?? null,
    nbr: saida.pedido?.nbr ?? null,
    nomeEmpreendimento: saida.pedido?.nomeEmpreendimento ?? null,
    obrigacoesFornecedores: saida.pedido?.obrigacoesFornecedores ?? null,
    orcamentos: saida.orcamento ?? null,
    prazoContratacao: saida.pedido?.prazoContratacao ?? null,
    processoAnterior: saida.pedido?.processoAnterior ?? null,
    quantitativos: saida.pedido?.quantitativos ?? null,
    razaoSocial: saida.pedido?.razaoSocial ?? null,
    relacaoProjetos: saida.pedido?.relacaoProjetos ?? null,
    saldoMedicaoPagar: saida.pedido?.saldoMedicaoPagar ?? null,
    tipoAditivo: saida.pedido?.tipoAditivo ?? null,
    tipoServico: saida.pedido?.descricaoServico ?? null,
    valorPagar: saida.pedido?.valorPagar ?? null,
    dataPedido: saida.pedido?.dataPedido ?? null,
    acao: saida.pedido?.acao ?? null,
    responsavelPelaEtapa: saida.pedido?.responsavelPelaEtapa ?? null,
    etapa: saida.pedido?.etapa ?? null,
    etapaStatus: saida.pedido.etapaStatus ?? null
});

const pedidoSliceViewModelSaida = createSlice({
    name: 'pedidoViewModelSaida',
    initialState,
    reducers: {
        setDetalhePedido(state, action: PayloadAction<IDetalhePedidoViewModelSaida>) {
            const entrada = mapSaidaToEntrada(action.payload);
            return { ...state, ...entrada };
        },
    },
});

export const { setDetalhePedido } = pedidoSliceViewModelSaida.actions;
export default pedidoSliceViewModelSaida.reducer;