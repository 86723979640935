import { AxiosResponse } from "axios";
import { CodigoPLM } from "../../../Interface/ICodigoPLM";
import client from "../../config/client";

export const getServicos = async () => {
  try {
    const res = await client.get("Servico/listarTodos");
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getFornecedores = async (
  codigoEmpresa: string,
  codigoObra: string
) => {
  try {
    const res = await client.get(
      `Servico/buscar-fornecedores-por-empresa-obra?codigoEmpresa=${codigoEmpresa}&codigoObra=${codigoObra}`
    );
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getContratacoesIniciaisPorFornecedor = async (
  nomeFornecedor: string
): Promise<number[]> => {
  try {
    // Tipando a resposta com um array de números
    const res: number[] = await client.get(
      `Pedido/obter-contratacoes-iniciais-fornecedor?nomeFornecedor=${nomeFornecedor}`
    );
    return res; // Agora sabemos que 'res.data' é um array de números
  } catch (error: any) {
    // Tipando o erro
    throw new Error(`Erro ao carregar as contratações iniciais deste fornecedor selecionado: ${error.message}`);
  }
};
export const getComposicoes = async () => {
  try {
    const res = await client.get("Servico/listarComposicoes");
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getInsumos = async () => {
  try {
    const res = await client.get("Servico/listarInsumos");
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getCodigoPLM = async (
  codigoEmpresa: string,
  codigoObra: string
): Promise<CodigoPLM[]> => {
  try {
    const res: AxiosResponse<CodigoPLM[]> = await client.get(
      `Servico/listarCodigoPLM?codigoEmpresa=${codigoEmpresa}&codigoObra=${codigoObra}`
    );

    if (Array.isArray(res)) {
      return res;
    } else {
      throw new Error("Dados recebidos não são um array");
    }
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa: " + error.message);
  }
};
export const getPrazoBI = async (prazoBI: string, codigoObra: string) => {
  try {
    const res = await client.get(
      `Servico/tabela-prazos?servicoBI=${prazoBI}&codigoObra=${codigoObra}`
    );
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
