const SpinnerCriarPedido = () => {
    return (
        <>
            <div className="backdrop" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 2 }}></div>
            <div
                className="d-flex flex-column justify-content-center align-items-center position-fixed top-50 start-50 translate-middle"
                style={{
                    zIndex: 4,
                    backgroundColor: 'white',
                    padding: '1.5rem',
                    borderRadius: '0.5rem',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center'
                }}
            >
                <div
                    className="spinner-border text-primary"
                    style={{
                        width: "6rem",
                        height: "6rem",
                    }}
                    role="status"
                >
                    <span className="visually-hidden">Carregando...</span>
                </div>
                <span className="spinner-overlay" style={{ marginTop: '1rem', fontWeight: '500', textShadow: '1px 2px rgba(255, 255, 255, 0.5)' }}>
                    O pedido está sendo criado.<br /> Por favor, não atualize ou feche esta página.
                </span>
            </div>
        </>
    );
};

export default SpinnerCriarPedido;