import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OpcaoInsumoComposicaoProps } from "../../Interface/IOpcaoInsumoComposicao";
import { setQuantitativosSelecionados } from "../../redux/pedido/sliceViewModelEntrada";


const OpcaoInsumoComposicao: React.FC<OpcaoInsumoComposicaoProps> = ({ insumos, composicoes }) => {
    const [opcoesInsumoComposicaoTeste, setOpcoesInsumoComposicaoTeste] = useState('Composicoes');
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const dispatch = useDispatch();
    const itens = opcoesInsumoComposicaoTeste === "Insumos" ? insumos : composicoes;
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const quantitativosSelecionados = useSelector((state: any) => state.pedidoSliceViewModelEntrada.quantitativos);

    const handleOptionSelectQuantitativo = (item: any) => {
        if (mode === 'create') {
            dispatch(setQuantitativosSelecionados({
                codigoComposicao: item.codigo,
                descricaoComposicao: item.descricao,
                unidade: opcoesInsumoComposicaoTeste === "Insumos" ? '' : item.unidadeComposicao,
                categoriaUAU: item.categoriaUAU,
                material: opcoesInsumoComposicaoTeste === "Insumos"
            }));
            setInputValue("");
            setSelectedValue(null);
        } else {
            dispatch(setQuantitativosSelecionados({
                codigoComposicao: item.codigo,
                descricaoComposicao: `*${item.descricao}`,
                unidade: opcoesInsumoComposicaoTeste === "Insumos" ? '' : item.unidadeComposicao,
                categoriaUAU: item.categoriaUAU,
                material: opcoesInsumoComposicaoTeste === "Insumos"
            }));
            setInputValue("");
            setSelectedValue(null);
        }
    };

    return (
        <div className="d-flex flex-column gap-4">
            <div className="d-flex gap-2">
                <Button
                    style={{
                        borderRadius: '0.375rem',
                        textTransform: 'none',
                        borderColor: '#0d6efd',
                        backgroundColor: opcoesInsumoComposicaoTeste === "Insumos" ? '#0d6efd' : 'transparent',
                        color: opcoesInsumoComposicaoTeste === "Insumos" ? '#fff' : '#0d6efd',
                        padding: '0.35rem 0.75rem',
                        minWidth: 'auto',
                        marginRight: '8px',
                        fontSize: '15px',
                        marginBottom: '20px',
                    }}
                    onClick={() => setOpcoesInsumoComposicaoTeste("Insumos")}
                    variant='outlined'
                >
                    Insumos
                </Button>
                <Button
                    style={{
                        borderRadius: '0.375rem',
                        textTransform: 'none',
                        padding: '0.35rem 0.75rem',
                        minWidth: 'auto',
                        borderColor: '#0d6efd',
                        fontSize: '15px',
                        marginBottom: '20px',
                        backgroundColor: opcoesInsumoComposicaoTeste === 'Composicoes' ? '#0d6efd' : 'transparent',
                        color: opcoesInsumoComposicaoTeste === 'Composicoes' ? '#fff' : '#0d6efd',
                    }}

                    onClick={() => setOpcoesInsumoComposicaoTeste("Composicoes")}
                    variant='outlined'
                >
                    Composições
                </Button>
            </div>
            <div className="form-floating">
                <Autocomplete
                    className="autoCompleteOrcamento"
                    options={
                        inputValue.length >= 3
                            ? itens.filter((itens: { codigo: string; descricao: string }) =>
                            (itens.codigo.toLowerCase().includes(inputValue.toLowerCase()) ||
                                itens.descricao.toLowerCase().includes(inputValue.toLowerCase()) &&
                                ((itens.codigo !== quantitativosSelecionados.find((o: any) => o.codigoComposicao === itens.codigo)?.codigoComposicao))))
                            : []
                    }
                    getOptionLabel={(option: any) => typeof option === 'string' ? option : `${option.codigo} - ${option.descricao}`}
                    inputValue={inputValue}
                    value={selectedValue}
                    onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            handleOptionSelectQuantitativo(newValue);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Itens da solicitação/cotação"
                        />
                    )}
                    popupIcon={null}
                    noOptionsText="Nenhuma opção encontrada"
                />
            </div>
        </div >
    );
};

export default OpcaoInsumoComposicao;
