import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatarMoedaBrasileira } from "../../Utils/FormatarMoedaBrasileira";
import './style.scss';
const formatarCNPJ = (value: string): string => {
    const cleanedValue = value.replace(/\D/g, "");
    if (cleanedValue.length === 14) {
        return cleanedValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
        );
    } else if (cleanedValue.length === 11) {
        return cleanedValue.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
        )
    }
    return value;
};
const DadosUAU: React.FC = () => {
    const [fornecedorDivergente, setFornecedorDivergente] = useState<boolean>(false);
    const [valorDivergente, setValorDivergente] = useState<boolean>(false);
    const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
    const dadosFornecedor = pedidoExistente.fornecedores;

    const verificaDivergencia = async () => {
        const valorContratacaoNumerico = Number(
            dadosFornecedor.valorContratacao.replace(/[R$\s.]/g, "").replace(",", ".")
        );

        const valorContratoUAUNumerico = Number(dadosFornecedor.valorContratoUAU);
        const fornecedorDivergente = dadosFornecedor.fornecedorVencedor !== dadosFornecedor.fornecedorVencedorUAU;
        const valorDivergente = valorContratacaoNumerico !== valorContratoUAUNumerico;
    
        setFornecedorDivergente(fornecedorDivergente);
        setValorDivergente(valorDivergente);
    };
    

    useEffect(() => {
        verificaDivergencia();
    }, []);

    return (
        <div className="formulario">
            <h5>Dados do contrato UAU</h5>
            <div className="dados-linha">
                
                <TextField
                    className={`inputForm fornecedor ${fornecedorDivergente ? "borda-vermelha" : ""}`}
                    label="Fornecedor vencedor:"
                    disabled
                    value={dadosFornecedor?.cnpjFornecedorVencedor && dadosFornecedor?.fornecedorVencedorUAU
                        ? `${formatarCNPJ(dadosFornecedor.cnpjFornecedorVencedor)} - ${dadosFornecedor.fornecedorVencedorUAU}`
                        : ''} />
                <TextField
                    className={`inputForm valor ${valorDivergente ? "borda-vermelha" : ""}`}
                    label="Valor da contratação:"
                    disabled
                    value={dadosFornecedor?.valorContratoUAU
                        ? formatarMoedaBrasileira(dadosFornecedor.valorContratoUAU)
                        : ''} />
            </div>
            <hr />
        </div>
    );
};

export default DadosUAU;
