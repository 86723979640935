import { useCallback, useEffect, useState } from "react";
import { getListaDeJustificativaPedidoEmergencial } from "../Repository/domain/Pedidos";

const useDataAssinatura = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<
    "success" | "warning" | "danger" | "info"
  >("success");

  const [dataDeAssinatura, setDataDeAssinatura] = useState("");
  const [prazoParaContratacao, setPrazoParaContratacao] = useState("");
  const [prazoParaExecucaoInicial, setPrazoParaExecucaoInicial] = useState("");
  const [prazoParaExecucaoFinal, setPrazoParaExecucaoFinal] = useState("");
  const [pedidoEmergencial, setPedidoEmergencial] = useState(false);
  const [justificativaEmergencial, setJustificativaEmergencial] = useState([]);
  const [justificativaSelecionada, setJustificativaSelecionada] = useState("");

  const showAlertMessage = useCallback(
    (message: string, type: "success" | "warning" | "danger" | "info") => {
      setAlertMessage(message);
      setAlertType(type);
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 10000);
    },
    []
  );

  const validarDataDeAssinatura = (data: string) => {
    const dataAtual = new Date().toISOString().split("T")[0];
    const prazo45Dias = new Date();
    prazo45Dias.setDate(new Date().getDate() + 45);

    if (data < dataAtual) {
      showAlertMessage(
        "Selecione uma data igual ou posterior à data atual.",
        "warning"
      );
      return false;
    }

    if (new Date(data) < prazo45Dias) {
      setPedidoEmergencial(true);
    } else {
      setPedidoEmergencial(false);
    }

    return true;
  };

  const validarPrazoInicial = (data: string) => {
    if (!validarDataDeAssinatura(dataDeAssinatura)) return false;

    if (data < dataDeAssinatura) {
      showAlertMessage(
        "O prazo inicial não pode ser anterior à data de assinatura do contrato.",
        "warning"
      );
      return false;
    }
    return true;
  };

  const validarPrazoFinal = (data: string) => {
    if (!validarPrazoInicial(prazoParaExecucaoInicial)) return false;

    if (data < prazoParaExecucaoInicial) {
      showAlertMessage(
        "O prazo final não pode ser anterior ao prazo inicial.",
        "warning"
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchListaDeJustificativaPedidoEmergencial = async () => {
      try {
        const response: any = await getListaDeJustificativaPedidoEmergencial();
        setJustificativaEmergencial(response);
      } catch (error) {
        alert("Erro ao buscar a lista de justificativa emergencial:" + error);
      }
    };
    fetchListaDeJustificativaPedidoEmergencial();
  }, []);

  return {
    showAlert,
    alertMessage,
    alertType,
    dataDeAssinatura,
    prazoParaContratacao,
    prazoParaExecucaoInicial,
    prazoParaExecucaoFinal,
    pedidoEmergencial,
    justificativaEmergencial,
    justificativaSelecionada,
    setDataDeAssinatura,
    setPrazoParaExecucaoInicial,
    setPrazoParaExecucaoFinal,
    setJustificativaSelecionada,
    validarDataDeAssinatura,
    validarPrazoInicial,
    validarPrazoFinal,
    setPedidoEmergencial
  };
};

export default useDataAssinatura;
