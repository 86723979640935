import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Anexos from "../../components/Anexos";
import DadosUAU from "../../components/DadosUAU";
import Navbar from "../../components/Navbar";
import ParecerAprovacao from "../../components/ParecerAprovacao";
import ResumoContratacao from "../../components/ResumoContratacao";
import Spinner from "../../components/Spinner";
import TabelaHistoricoAprovacao from "../../components/TabelaHistoricoAprovacao";
import TabelaResumoFinanceiro from "../../components/TabelaResumoFinanceiro";
import useDetalhesDoPedido from "../../Hooks/useDetalhesDoPedido";
import useHistoricoDoPedido from "../../Hooks/useHistoricoPedido";
import { useFinalizacaoPedidoSup3, usePermissao } from "../../Hooks/usePermissao";
import useResumoFinanceiro from "../../Hooks/useTabelaResumoFinanceiro";
import { Anexo, AnexoDetalhado } from "../../Interface/IAnexos";
import { LoadingProps } from "../../Interface/ILoading";
import { setDesabilitado, setDesabilitadoSolicitante, setDesabilitadoTabela, setMode } from "../../redux/pedido/slice";
import { setPedidoExistente } from "../../redux/pedido/sliceViewModelEntrada";
import { setDetalhePedido } from "../../redux/pedido/sliceViewModelSaida";
import { calcularValorOrcamento } from "../../Utils/CalcularValorOrcamento";

const AprovarPedido: React.FC<LoadingProps> = ({ setLoading }) => {
    const { numeroPedido } = useParams<{ numeroPedido: string }>();
    const numeroPedidoAsNumber = parseInt(numeroPedido ?? "0", 10);
    const detalhesDoPedido = useDetalhesDoPedido(numeroPedidoAsNumber);
    const [anexoAtualizado, setAnexoAtualizado] = useState<Anexo[]>([]);
    const [anexoRemovido, setAnexoRemovido] = useState<Anexo[]>([]);
    const dadosResumoFinanceiro = useResumoFinanceiro(numeroPedidoAsNumber);
    const historicoDoPedido = useHistoricoDoPedido(numeroPedidoAsNumber);
    const contemAditivo = useMemo(() => {
        return dadosResumoFinanceiro.filter((resumoFinanceiro) => resumoFinanceiro.codigoPedido);
    }, [dadosResumoFinanceiro]);
    const navegar = localStorage.getItem("navegar") || "";
    const pedidoExistente = useSelector((state: any) => state.pedidoSliceViewModelSaida);
    const dadosPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const dispatch = useDispatch();
    const location = useLocation();
    const responsavelPelaEtapa = pedidoExistente?.responsavelPelaEtapa;
    const temPermissao = usePermissao();
    const [anexosIniciais, setAnexosIniciais] = useState<AnexoDetalhado[]>([]);
    const finalizarPedidoSup3 = useFinalizacaoPedidoSup3();
    const codigoUsuario = localStorage.getItem("codigoUsuario");

    useEffect(() => {
        if (detalhesDoPedido && navegar !== "true") {
            dispatch(setDetalhePedido(detalhesDoPedido));
        }
        if (detalhesDoPedido && detalhesDoPedido.anexos) {
            setAnexosIniciais(detalhesDoPedido.anexos);
        }
    }, [detalhesDoPedido]);

    const formatarOrcamentoItens = (orcamentos: any) => {
        return orcamentos?.orcamentoItens?.map((item: any) => ({
            ...item,
            valorOrcamento: calcularValorOrcamento(item.quantidadeOrcada, item.valorUnitario),
            valorAjustado: calcularValorOrcamento(item.quantidadeOrcada, item.valorUnitario),
        }));
    };

    useEffect(() => {
        if (location.pathname.includes("aprovacoes") && navegar !== "true") {
            dispatch(setMode("view"));
            dispatch(setDesabilitado(true));
        }
    }, [])

    const aprovacoesExtraidas = historicoDoPedido.map((item: any) => item.aprovacao);
    const pedidoReprovado = aprovacoesExtraidas[0] === 'Reprovado';

    useEffect(() => {
        const pedidoReprovadoSup2 = pedidoReprovado && responsavelPelaEtapa === "Suprimentos 1";
        const pedidoReprovadoApr = pedidoReprovado && responsavelPelaEtapa === "Solicitante";
        const desabilitado = pedidoReprovado || pedidoReprovadoSup2;
        if (mode === "view") {
            if (pedidoExistente && historicoDoPedido && navegar !== "true") {
                dispatch(setDesabilitadoTabela(!desabilitado));
                dispatch(setDesabilitadoSolicitante(!pedidoReprovadoApr));
            };
            if (pedidoReprovadoApr) {
                dispatch(setDesabilitado(!pedidoReprovadoApr));
            } else if (temPermissao && navegar !== "true") {
                dispatch(setDesabilitado(false));
            }
        }
    }, [pedidoExistente, temPermissao, pedidoReprovado]);

    useEffect(() => {
        if (pedidoExistente && mode === "view" && navegar !== "true") {
            dispatch(setPedidoExistente({
                ...pedidoExistente,
                codigoUsuario: codigoUsuario,
                orcamentos: {
                    ...pedidoExistente.orcamentos,
                    orcamentoItens: formatarOrcamentoItens(pedidoExistente.orcamentos),
                },
            }));
        }
    }, [pedidoExistente, mode, navegar, dispatch]);


    if (detalhesDoPedido === null) {
        return <Spinner />;
    }
    
    return (
        <div className="d-flex flex-column gap-4 w-100">
            <Navbar numeroPedido={dadosPedido.codigoPedido} anexoAtualizado={anexoAtualizado} />
            <ResumoContratacao />
            <hr />
            {finalizarPedidoSup3 && (
                <DadosUAU />
            )}
            {contemAditivo && contemAditivo.length > 0 && pedidoExistente.descricaoTipoSolicitacao != "Contratação Inicial" && (
                <div>
                    <TabelaResumoFinanceiro dadosResumoFinanceiro={dadosResumoFinanceiro} />
                    <hr />
                </div>
            )}
            <TabelaHistoricoAprovacao historicoPedido={historicoDoPedido} />
            <Anexos anexosIniciais={anexosIniciais} setAnexoAtualizado={setAnexoAtualizado} setAnexoRemovido={setAnexoRemovido} historicoPedido={historicoDoPedido} />
            <hr />
            <ParecerAprovacao
                idPedido={parseInt(dadosPedido.codigoPedido)}
                contrato={dadosPedido.contratoUAU}
                setLoading={setLoading}
                anexoAtualizado={anexoAtualizado}
                anexoRemovido={anexoRemovido}
                anexosPedido={detalhesDoPedido.anexos}
                dadosResumoFinanceiro={dadosResumoFinanceiro} />
        </div>
    );
};

export default AprovarPedido;
